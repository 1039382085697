<template>
  <div>
    <!-- modal vertical center -->
    <b-modal
      id="add-lesson"
      ref="add-lesson"
      centered
      title="Add new user"
      hide-footer
      size="lg"
    >
      <b-card-text>
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Name"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="newRecord.displayName"
                  placeholder="The name to display in the address book for the user."
                />
              </b-form-group>
            </b-col>
            <!--            <b-col cols="12">-->
            <!--              <b-form-group-->
            <!--                label="Phone"-->
            <!--                label-for="h-first-name"-->
            <!--                label-cols-md="4"-->
            <!--              >-->
            <!--                <b-form-input-->
            <!--                  v-model="newRecord.mobilePhone"-->
            <!--                  type="phone"-->
            <!--                  placeholder="Phone"-->
            <!--                />-->
            <!--              </b-form-group>-->
            <!--            </b-col>-->
            <!--            <b-col-->
            <!--              v-if="!update_record.isUpdate"-->
            <!--              cols="12"-->
            <!--            >-->
            <!--              <b-form-group-->
            <!--                label="mail Nickname"-->
            <!--                label-for="h-first-name"-->
            <!--                label-cols-md="4"-->
            <!--              >-->
            <!--                <b-form-input-->
            <!--                  v-model="newRecord.mailNickname"-->
            <!--                  placeholder="The mail alias for the user."-->
            <!--                />-->
            <!--              </b-form-group>-->
            <!--            </b-col>-->
            <b-col cols="12">
              <b-form-group
                label="Email"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="newRecord.otherMails"
                  type="text"
                  placeholder="Email address (reset password)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Username"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-input-group>
                  <b-form-input
                    v-model="newRecord.userPrincipalName"
                    placeholder="userPrincipalName"
                  />
                  <b-input-group-append>
                    <b-input-group-text><strong class="text-danger">@</strong></b-input-group-text>
                  </b-input-group-append>
                  <b-input-group-append>
                    <b-form-select
                      v-model="selected_domain"
                      :options="list_domain"
                      value-field="id"
                      text-field="id"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col
              v-if="!update_record.isUpdate"
              cols="12"
            >
              <b-form-group
                label="Password"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <!--                <b-form-input-->
                <!--                  v-model="newRecord.passwordProfile.password"-->
                <!--                  placeholder="password"-->
                <!--                />-->
                <b-input-group>
                  <b-form-input
                    v-model="newRecord.passwordProfile.password"
                    placeholder="password"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-success"
                      @click="generatePassword"
                    >
                      Generate
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              v-if="!update_record.isUpdate"
              cols="12"
            >
              <b-form-group
                label="License"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="isActiveLicense"
                  name="checkbox-1"
                  value="true"
                  unchecked-value="false"
                >
                  Active License
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                @click="addNewRecord"
              >
                {{ $t('Submit') }}
              </b-button>
              <b-button
                variant="outline-secondary"
                @click="resetForm"
              >
                {{ $t('Reset') }}
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </b-card-text>
    </b-modal>

    <!-- modal vertical center -->
    <b-modal
      id="add-group"
      ref="add-group"
      centered
      title="Add member to group"
      hide-footer
      size="sm"
    >
      <b-card-text>
        <b-form @submit.prevent>
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group
                label="Group"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-select
                  v-model="selected_group"
                  :options="list_groups"
                  value-field="id"
                  text-field="displayName"
                />
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button
                v-if="!isRemoveFromGroup"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="addMemberGroup"
              >
                {{ $t('Add') }}
              </b-button>
              <!--                <b-button-->
              <!--                    type="submit"-->
              <!--                    variant="danger"-->
              <!--                    class="mr-1"-->
              <!--                    @click="removeMemberGroup"-->
              <!--                    v-else-->
              <!--                >-->
              <!--                    {{ $t('Remove') }}-->
              <!--                </b-button>-->
            </b-col>
          </b-row>

        </b-form>
      </b-card-text>
    </b-modal>

    <!-- input search -->
    <b-row class="mb-2">

      <!-- Per Page -->
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-md-0"
      >
        <div class="custom-search d-flex justify-content-center">
          <b-form-group>
            <div class="mb-1">
              <b-button
                v-b-modal.add-lesson
                variant="secondary"
                @click="$router.go(-1)"
              >
                <feather-icon
                  icon="ArrowLeftCircleIcon"
                  class="mr-50"
                />
                <span>{{ $t('Back') }}</span>
              </b-button>
            </div>
            <div class="">
              <b-button
                v-b-modal.add-lesson
                variant="primary"
                @click="resetForm"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                {{ $t('Add User') }}
              </b-button>

            </div>

          </b-form-group>

        </div>

      </b-col>

      <!-- Search -->
      <b-col
        cols="12"
        md="6"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block"
            placeholder="Search..."
          />
        </div>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      ref="list-user"
      style-class="vgt-table bordered striped"
      :line-numbers="false"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: false, // disable the select info panel on top
        selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      @on-selected-rows-change="selectionChanged"
    >
      <div slot="selected-row-actions">
        <button
          v-b-modal.add-group
          class="btn btn-primary mr-1"
          @click="isRemoveFromGroup = false"
        >
          Add to group
        </button>
        <!--          <button-->
        <!--              v-b-modal.add-group-->
        <!--              class="btn btn-danger"-->
        <!--              @click="isRemoveFromGroup = true"-->
        <!--          >-->
        <!--              Remove from group-->
        <!--          </button>-->
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'displayName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.displayName }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'otherMails'">
          <span>{{ props.row.otherMails.toString() }}</span>
        </span>

        <span v-else-if="props.column.field === 'licenseAssignmentStates'">
          <template v-if="props.row.licenseAssignmentStates.length > 0">
            <span v-for="item in props.row.licenseAssignmentStates">
              <template v-if="item.skuId === 'e82ae690-a2d5-4d76-8d30-7c6e01e6022e'">
                <b-badge
                  v-if="item.state"
                  class="bg-success"
                >
                  {{ item.state }}
                </b-badge>
                <b-badge
                  v-else
                  class="bg-danger"
                >
                  No license
                </b-badge>
              </template>
            </span>
          </template>

          <template v-else>
            <b-badge class="bg-danger">
              No license
            </b-badge>
          </template>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant('Active')">
            {{ `Active` }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                @click="addLicenseUser(props.row)"
              >
                <feather-icon
                  icon="FilePlusIcon"
                  class="mr-50"
                />
                <span>Add license</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-b-modal.add-lesson
                @click="openFormUpdate(props)"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteRecord(props.row)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
              <b-dropdown-item @click="resetPassword(props.row)">
                <feather-icon
                  icon="RotateCcwIcon"
                  class="mr-50"
                />
                <span>Reset Password</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BInputGroupAppend,
    BInputGroupPrepend, BInputGroupAddon, BInputGroupText, BInputGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { getUserData } from '@/auth/utils'

export default {
    name: 'List',
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BInputGroupAppend,
        BInputGroupPrepend,
        BInputGroupAddon,
        BInputGroupText,
        BInputGroup,
    },
    data() {
        return {
            pageLength: 20,
            dir: false,
            columns: [
                {
                    label: 'Name',
                    field: 'displayName',
                },
                {
                    label: 'Username',
                    field: 'userPrincipalName',
                },
                {
                    label: 'Email (Reset Password)',
                    field: 'otherMails',
                },
                {
                    label: 'License Assignment States',
                    field: 'licenseAssignmentStates',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',
            status: [],
            newRecord: {
                accountEnabled: true,
                displayName: '',
                userPrincipalName: '',
                passwordProfile: {
                    forceChangePasswordNextSignIn: true,
                    password: '',
                },
                mailNickname: '',
                otherMails: '',
                country: 'VN',
                usageLocation: 'VN',
                preferredLanguage: 'vi-VN',
                userType: 'Member',
            },
            update_record: {
                isUpdate: false,
                id: '',
            },
            list_domain: [],
            selected_domain: '',
            list_groups: [],
            selected_group: '',
            isRemoveFromGroup: false,
            isActiveLicense: false,
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                Current      : 'light-primary',
                Active : 'light-success',
                Rejected     : 'light-danger',
                Resigned     : 'light-warning',
                Applied      : 'light-info',
                /* eslint-enable key-spacing */
            }

            return (status) => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    async created() {
        await this.$swal_show()
        await this.getListDomain()
        await this.getListGroup()
        await this.getListUser()
        // await this.getListLicense()
        await this.$swal_hide()
    },
    methods: {
        generatePassword() {
            const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*ABCDEFGHIJKLMNOPQRSTUVWXYZ'
            const passwordLength = 12
            let password = ''
            for (let i = 0; i <= passwordLength; i++) {
                const randomNumber = Math.floor(Math.random() * chars.length)
                password += chars.substring(randomNumber, randomNumber + 1)
            }
            this.newRecord.passwordProfile.password = password.toString()
            // return password.toString()
        },
        async getListGroup() {
            await this.$http.post('/third-party/list-group')
                .then((response) => {
                    const { data, message, success } = response.data
                    if (success) {
                        this.list_groups = data.value
                    } else {
                        this.$error(message)
                    }
                })
                .catch((error) => {
                    const status = _.get(error, 'response.status', 'unknown')
                    const reason = _.get(error, 'response.statusText', 'unknown')
                    const message = _.get(error, 'response.data.message', 'unknown')

                    if (_.isArray(message)) {
                        message.forEach((msg) => {
                            this.$error(msg)
                        })
                    } else {
                        this.$error(message)
                    }
                })
        },
        isSuperAdmin() {
            const userData = getUserData()
            return userData.ability[0].action === 'manage'
        },
        async getListDomain() {
            const userData = getUserData()
            if (this.isSuperAdmin()) {
                await this.$http.post('/third-party/list-domain')
                    .then(async (response) => {
                        const { data, message, success } = response.data
                        if (success) {
                            this.list_domain = data.value
                        } else {
                            this.$error(message)
                        }
                    })
                    .catch((error) => {
                        const status = _.get(error, 'response.status', 'unknown')
                        const reason = _.get(error, 'response.statusText', 'unknown')
                        const message = _.get(error, 'response.data.message', 'unknown')

                        if (_.isArray(message)) {
                            message.forEach((msg) => {
                                this.$error(msg)
                            })
                        } else {
                            this.$error(message)
                        }
                    })
            } else {
                // this.list_domain = userData.domain
                this.list_domain = _.filter(userData.domain, (item) => item.id)
            }
        },
        async getListUser() {
            this.rows = []
            await this.$http.post('/third-party/list-user')
                .then((response) => {
                    const { data, message, success } = response.data
                    if (success) {
                        this.rows = data.value
                    } else {
                        this.$error(message)
                    }
                })
                .catch((error) => {
                    const status = _.get(error, 'response.status', 'unknown')
                    const reason = _.get(error, 'response.statusText', 'unknown')
                    const message = _.get(error, 'response.data.message', 'unknown')

                    if (_.isArray(message)) {
                        message.forEach((msg) => {
                            this.$error(msg)
                        })
                    } else {
                        this.$error(message)
                    }
                })
        },
        async getListLicense() {
            await this.$http.post('/third-party/list-license')
                .then((response) => {
                    const { data, message, success } = response.data
                    if (success) {
                        // console.log(data)
                    } else {
                        this.$error(message)
                    }
                })
                .catch((error) => {
                    const status = _.get(error, 'response.status', 'unknown')
                    const reason = _.get(error, 'response.statusText', 'unknown')
                    const message = _.get(error, 'response.data.message', 'unknown')

                    if (_.isArray(message)) {
                        message.forEach((msg) => {
                            this.$error(msg)
                        })
                    } else {
                        this.$error(message)
                    }
                })
        },
        async addLicenseUser(record) {
            const license = {
                addLicenses: [
                    {
                        disabledPlans: [
                            'a23b959c-7ce8-4e57-9140-b90eb88a9e97',
                            'c4801e8a-cb58-4c35-aca6-f2dcc106f287',
                            'a55dfd10-0864-46d9-a3cd-da5991a3e0e2',
                            '40b010bb-0b69-4654-ac5e-ba161433f4b4',
                            'a9b86446-fa4e-498f-a92a-41b447e03337',
                            '54fc630f-5a40-48ee-8965-af0503c1386e',
                            '94065c59-bc8e-4e8b-89e5-5138d471eaff',
                            'b8afc642-032e-4de5-8c0a-507a7bba7e5d',
                            'c87f142c-d1e9-4363-8630-aaea9c4d9ae5',
                            '1d0f309f-fdf9-4b2a-9ae7-9c48b91f1426',
                            'c33802dd-1b50-4b9a-8bb9-f13d2cdeadac',
                            '9e700747-8b1d-45e5-ab8d-ef187ceec156',
                            '8c7d2df8-86f0-4902-b2ed-a0458298f3b3',
                            '76846ad7-7776-4c40-a281-a386362dd1b9',
                            'c68f8d98-5534-41c8-bf36-22fa496fa792',
                            'bea4c11e-220a-4e6d-8eb8-8ea15d019f90',
                            '9b5de886-f035-4ff2-b3d8-c9127bea3620',
                            'b737dad2-2f6c-4c65-90e3-ca563267e8b9',
                            'a23b959c-7ce8-4e57-9140-b90eb88a9e97',
                            '882e1d05-acd1-4ccb-8708-6ee03664b117',
                            '2078e8df-cff6-4290-98cb-5408261a760a',
                            '0feaeb32-d00e-4d66-bd5a-43b5b83db82c',
                        ],
                        skuId: 'e82ae690-a2d5-4d76-8d30-7c6e01e6022e',
                    },
                ],
                removeLicenses: [],
            }
            await this.$swal_show()
            await this.$http.post('/third-party/add-license-user', { user_id: record.id, license })
                .then((response) => {
                    const { data, message, success } = response.data
                    if (success) {
                        this.$success('Add license success')
                    } else {
                        this.$error(message)
                    }
                })
                .catch((error) => {
                    this.$error(error.message)
                })
            await this.$swal_hide()
        },
        resetForm() {
            this.newRecord = {
                accountEnabled: true,
                displayName: '',
                userPrincipalName: '',
                passwordProfile: {
                    forceChangePasswordNextSignIn: true,
                    password: '',
                },
                mailNickname: '',
                otherMails: '',
                country: 'VN',
                usageLocation: 'VN',
                preferredLanguage: 'vi-VN',
                userType: 'Member',
            }
        },
        hideModal(modalID) {
            this.$refs[modalID].hide()
        },
        openFormUpdate(props) {
            this.update_record.isUpdate = true
            this.update_record.id = props.row.id
            this.newRecord = {
                displayName: props.row.displayName,
                mobilePhone: props.row.mobilePhone,
                userPrincipalName: props.row.userPrincipalName.split('@')[0],
                // mailNickname: props.row.mailNickname,
                otherMails: props.row.otherMails.toString(),
            }
            this.selected_domain = props.row.userPrincipalName.split('@')[1]
        },
        async addNewRecord() {
            await this.$swal_show(3000)
            this.newRecord.mailNickname = this.newRecord.userPrincipalName.slice()
            if (this.update_record.isUpdate) {
                await this.updateRecord(this.update_record.id, this.newRecord, this.selected_domain)
            } else {
                await this.$http.post('/third-party/add-user', { selectedDomain: this.selected_domain, newUser: this.newRecord })
                    .then(async (res) => {
                        const { data, message, success } = res.data
                        if (success) {
                            if (this.isActiveLicense) {
                                await this.addLicenseUser(data)
                            }
                            setTimeout(async () => {
                                await this.getListUser()
                                await this.$swal_hide()
                            }, 3000)

                            this.hideModal('add-lesson')
                            this.$swal({
                                title: 'Good job!',
                                text: 'Add new user success!',
                                icon: 'success',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                showClass: {
                                    popup: 'animate__animated animate__tada',
                                },
                                buttonsStyling: false,
                            })
                        } else {
                            await this.$swal_hide()
                            this.$error(message)
                        }
                    }).catch((error) => {
                        this.$swal_hide()
                        const status = _.get(error, 'response.status', 'unknown')
                        const reason = _.get(error, 'response.statusText', 'unknown')
                        const message = _.get(error, 'response.data.message', 'unknown')

                        if (_.isArray(message)) {
                            message.forEach((msg) => {
                                this.$error(msg)
                            })
                        } else {
                            this.$error(message)
                        }
                    })
            }
        },
        async deleteRecord(record) {
            await this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(async (result) => {
                if (result.value) {
                    await this.$swal_show()
                    await this.$http.post('/third-party/delete-user', { user_id: record.id })
                        .then(async (res) => {
                            const { data, message, success } = res.data
                            if (success) {
                                setTimeout(async () => {
                                    await this.getListUser()
                                    await this.$swal_hide()
                                }, 3000)
                                this.$success('Delete success')
                            } else {
                                this.$swal_hide()
                                this.$error(message)
                            }
                        }).catch((error) => {
                            const status = _.get(error, 'response.status', 'unknown')
                            const reason = _.get(error, 'response.statusText', 'unknown')
                            const message = _.get(error, 'response.data.message', 'unknown')
                            this.$swal_hide()
                            if (_.isArray(message)) {
                                message.forEach((msg) => {
                                    this.$error(msg)
                                })
                            } else {
                                this.$error(message)
                            }
                        })

                    this.$swal({
                        icon: 'success',
                        title: 'Deleted!',
                        text: 'Your record has been deleted.',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                } else if (result.dismiss === 'cancel') {
                    this.$swal({
                        title: 'Cancelled',
                        text: 'Your imaginary file is safe :)',
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                }
            })
        },
        async resetPassword(record) {
            if (record.otherMails.length <= 0 || !record.otherMails) {
                this.$error('Email recipient reset password is not found')
                return
            }
            await this.$swal_show()
            await this.$http.post('/third-party/reset-password-user', { user_id: record.id, username: record.userPrincipalName, email: record.otherMails[0] })
                .then((res) => {
                    const { data, message, success } = res.data
                    if (success) {
                        this.$success('Reset Password success')
                    } else {
                        this.$error(message)
                    }
                }).catch((error) => {
                    const status = _.get(error, 'response.status', 'unknown')
                    const reason = _.get(error, 'response.statusText', 'unknown')
                    const message = _.get(error, 'response.data.message', 'unknown')

                    if (_.isArray(message)) {
                        message.forEach((msg) => {
                            this.$error(msg)
                        })
                    } else {
                        this.$error(message)
                    }
                })
            await this.$swal_hide()
        },
        async updateRecord(id, update, selectedDomain) {
            this.$swal_show(3000)
            await this.$http.post('/third-party/update-user', { user_id: id, updateUser: update, selectedDomain })
                .then(async (res) => {
                    const { data, message, success } = res.data
                    if (success) {
                        this.update_record.isUpdate = false
                        this.update_record.id = ''
                        setTimeout(async () => {
                            await this.getListUser()
                            await this.$swal_hide()
                        }, 3000)
                        this.hideModal('add-lesson')
                        this.$success('Update success')
                    } else {
                        this.$swal_hide()
                        this.$error(message)
                    }
                }).catch((error) => {
                    this.$swal_hide()
                    const status = _.get(error, 'response.status', 'unknown')
                    const reason = _.get(error, 'response.statusText', 'unknown')
                    const message = _.get(error, 'response.data.message', 'unknown')

                    if (_.isArray(message)) {
                        message.forEach((msg) => {
                            this.$error(msg)
                        })
                    } else {
                        this.$error(message)
                    }
                })
        },
        async selectionChanged(params) {
            // console.log(params)
        },
        async addMemberGroup() {
            const listMember = {
                'members@odata.bind': [],
            }
            // console.log('listMember', this.$refs['list-user'].selectedRows)
            if (this.$refs['list-user'].selectedRows.length <= 0) {
                this.$error('Please select a member')
                return
            }
            await this.$swal_show()
            for (let i = 0; i < this.$refs['list-user'].selectedRows.length; i++) {
                const id = `https://graph.microsoft.com/v1.0/directoryObjects/${this.$refs['list-user'].selectedRows[i].id}`
                listMember['members@odata.bind'].push(id)
            }

            await this.$http.post('/third-party/add-member-group', { group_id: this.selected_group, list_member: listMember })
                .then(async (res) => {
                    const { data, message, success } = res.data
                    if (success) {
                        await this.getListUser()
                        this.hideModal('add-group')
                        this.$swal({
                            title: 'Good job!',
                            text: 'Add member to group success!',
                            icon: 'success',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            showClass: {
                                popup: 'animate__animated animate__tada',
                            },
                            buttonsStyling: false,
                        })
                    } else {
                        this.$error(message)
                    }
                }).catch((error) => {
                    const status = _.get(error, 'response.status', 'unknown')
                    const reason = _.get(error, 'response.statusText', 'unknown')
                    const message = _.get(error, 'response.data.message', 'unknown')

                    if (_.isArray(message)) {
                        message.forEach((msg) => {
                            this.$error(msg)
                        })
                    } else {
                        this.$error(message)
                    }
                })
            await this.$swal_hide()
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.back-btn {
  margin-bottom: 14px;
}
</style>
